import React from 'react'
import { View } from 'react-native'
import UnderRework from '../../components/general/UnderRework'
import OpticianCalendar from '../../components/Optician/OpticianCalendar'
import OpticianDashboardCard from '../../components/Optician/OpticianDashboardCard'
import OpticianDrawer from '../../components/screens/OpticianDrawer'

export default ({ navigation }) => {
  return (
    <OpticianDrawer navigation={navigation}>
      <View style={{ width: '100%' }}>
        <View style={{ flex: 3, flexDirection: 'row', flexWrap: 'wrap' }}>
          <OpticianDashboardCard
            icon='calendar-month-outline'
            header='Nächste Termine'
          >
            <OpticianCalendar />
          </OpticianDashboardCard>
          <OpticianDashboardCard
            icon='message-alert-outline'
            header='Letzte Chatnachrichten'
          >
            <UnderRework />
          </OpticianDashboardCard>
          <OpticianDashboardCard icon='newspaper' header='Neuigkeiten'>
            <UnderRework />
          </OpticianDashboardCard>
          <OpticianDashboardCard icon='chart-line' header='Statistik'>
            <UnderRework />
          </OpticianDashboardCard>
        </View>
        <View style={{ flex: 1 }} />
      </View>
    </OpticianDrawer>
  )
}
