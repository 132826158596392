import React, { useState } from 'react'
import { FlatList, Text, TouchableOpacity, View } from 'react-native'
import theme from '../../styles/theme'
import { Divider, IconButton } from 'react-native-paper'

export default props => {
  const [time, setTime] = useState(props.initial)
  const [page, setPage] = useState(1)

  const data = () => {
    let res = []
    let h = pagenationLimits().min
    let max = pagenationLimits().max
    let m
    for (; h <= max; h++) {
      for (m = 0; m < 60; m += 15) {
        res.push(h + ':' + (m < 10 ? '0' + m : m.toFixed(0)))
      }
      // res.push(h + 1 + ':00')
    }
    return res
  }

  const renderItem = ({ item }) => {
    return (
      <TouchableOpacity
        style={{
          marginHorizontal: 6,
          marginVertical: 5,
          height: 35,
          minWidth: 65,
          borderRadius: 30,
          backgroundColor: item === time ? theme.colors.vibrant : 'transparent',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onPress={() => {
          setTime(item)
          if (typeof props.onChange === 'function') props.onChange(item)
        }}
      >
        <Text
          style={{
            color: item === time ? '#fff' : '#111',
            textAlign: 'center',
            alignSelf: 'center',
            fontSize: item === time ? 19 : 20,
            fontWeight: item === time ? '400' : '300'
          }}
        >
          {item}
        </Text>
      </TouchableOpacity>
    )
  }

  const formatPage = () => {
    switch (page) {
      case 0:
        return 'Vormittags'
      case 1:
        return 'Mittags'
      case 2:
        return 'Nachmittags'
      case 3:
        return 'Abends'
      default:
        return 'Vormittags'
    }
  }

  const pagenationLimits = () => {
    switch (page) {
      case 0:
        return { min: 7, max: 11 }
      case 1:
        return { min: 10, max: 14 }
      case 2:
        return { min: 13, max: 17 }
      case 3:
        return { min: 16, max: 20 }
      default:
        return { min: 16, max: 20 }
    }
  }

  const handlePagenation = val => {
    if (page + val < 4 && page + val >= 0) setPage(page + val)
  }

  return (
    <View>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <IconButton
          icon={'chevron-left'}
          color={page === 0 ? theme.colors.disabled : theme.colors.vibrant}
          size={30}
          onPress={() => handlePagenation(-1)}
        />
        <Divider style={{ flex: 1, marginHorizontal: 8 }} />
        <Text style={{ color: theme.colors.gray, marginHorizontal: 10 }}>
          {formatPage()}
        </Text>
        <Divider style={{ flex: 1, marginHorizontal: 8 }} />
        <IconButton
          icon={'chevron-right'}
          color={page === 3 ? theme.colors.disabled : theme.colors.vibrant}
          size={30}
          onPress={() => handlePagenation(1)}
        />
      </View>
      <FlatList
        style={{ flex: 1, alignSelf: 'center' }}
        contentContainerStyle={[
          {
            // flexDirection: 'row',
            // justifyContent: 'space-between'
          },
          props.style
        ]}
        horizontal={false}
        numColumns={5}
        {...props}
        data={data()}
        renderItem={renderItem}
        keyExtractor={item => item}
      />
    </View>
  )
}
