import React from 'react'
import { Button, Paragraph } from 'react-native-paper'
import { Image, View } from 'react-native'
import { useLinkTo } from '@react-navigation/native'

export default () => {
  const linkTo = useLinkTo()
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        minWidth: 200,
        alignSelf: 'center',
        alignItems: 'center'
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          width: '100%',
          paddingHorizontal: 40,
          alignSelf: 'center',
          alignItems: 'center'
        }}
      >
        <Image
          source={require('../../../assets/undraw/shop.png')}
          style={{ width: 1082 * 0.28, height: 838 * 0.28, marginTop: 10 }}
        />
        <Paragraph>Bitte wählen Sie einen Stamm-Optiker aus.</Paragraph>
        <Button
          style={{ marginTop: 20 }}
          mode='contained'
          onPress={() => linkTo('/map')}
        >
          Jetzt Optiker auswählen
        </Button>
      </View>
    </View>
  )
}
