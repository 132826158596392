import React, { useState, useEffect } from 'react'
import { Text, View } from 'react-native'
import { Agenda } from 'react-native-calendars'
import { Button, Portal } from 'react-native-paper'
import { useUser } from '../../hooks/user'
import CalendarItem from './Common/CalendarItem'
import CreateAppointmentModal from './CreateAppointmentModal'
import { useAppointment } from '../../hooks/appointments'
import { useOptician } from '../../hooks/opticians'

export default () => {
  const [appointmentFormVisible, setAppointmentFormVisible] = useState(false)

  const { userAttributes } = useUser()
  const { appointments, fetchAppointments } = useAppointment()
  const { customers, getCustomerFromID } = useOptician()

  useEffect(() => {
    fetchAppointments()
  }, [])

  const renderItem = items => {
    if (!items) return <View />
    return (
      <>
        {items.map((item, i) => (
          <CalendarItem key={i} {...item} />
        ))}
      </>
    )
  }

  const calendarItems = () => {
    let result = {}
    if (!appointments) return result
    appointments.map(x => {
      let date = formatDateFromTimestamp(x.appointmentTimestamp)
      let userObj =
        customers?.length > 0
          ? getCustomerFromID(customers, x.participants)
          : undefined
      let agendaItem = [
        {
          customer: userObj?.length > 0 ? userObj[0].item : undefined,
          ...x
        }
      ]

      if (Array.isArray(result[date])) {
        result[date].push(agendaItem)
      } else result[date] = [agendaItem]
    })
    return result
  }

  function formatDate () {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  function formatDateFromTimestamp (t) {
    var d = new Date(t),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  return (
    <>
      <Portal>
        <CreateAppointmentModal
          visible={appointmentFormVisible}
          onDismiss={() => setAppointmentFormVisible(false)}
        />
      </Portal>
      <View style={{ justifyContent: 'space-between' }}>
        <Agenda
          items={calendarItems()}
          renderItem={item => {
            return renderItem(item)
          }}
          selected={formatDate()}
          style={{ maxHeight: 550 }}
        />
        <Button
          disabled={
            !userAttributes || !userAttributes['custom:localOpticianId']
          }
          mode='text'
          uppercase={false}
          onPress={() => setAppointmentFormVisible(true)}
          style={{ alignSelf: 'flex-end', width: 250, marginVertical: 12 }}
        >
          <Text style={{ fontSize: 16 }}>Neuer Termin</Text>
        </Button>
      </View>
    </>
  )
}
