import React, { useState } from 'react'
import { HelperText, TextInput } from 'react-native-paper'
import theme from '../../styles/theme'

export default props => {
  const [isFocused, setFocused] = useState(false)

  const errorProps = props.errorProps

  return (
    <>
      <TextInput
        mode='flat'
        placeholder='Terminname'
        style={[
          {
            marginVertical: 8,
            backgroundColor: '#fff',
            borderWidth: 0,
            flex: props.flex || 0,
            height: props.multiline ? 131 : 64,
            marginTop: 6
          },
          props.style
        ]}
        left={
          props.icon ? (
            <TextInput.Icon name={props.icon} style={{ marginLeft: -12 }} />
          ) : (
            undefined
          )
        }
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={props.value || ''}
        onChangeText={val => props.onChangeText(val)}
        underlineColor={isFocused ? theme.colors.primary : '#666'}
        {...props}
      />
      {props.errorText && (
        <HelperText type='error' style={[{}, errorProps.style]} {...errorProps}>
          {props.errorText}
        </HelperText>
      )}
    </>
  )
}
