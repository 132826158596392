import React from 'react'
import { Paragraph } from 'react-native-paper'
import ChatComponent from '../components/Chat/Chat.component'
import LocalOpticianRequired from '../components/general/LocalOpticianRequired'
import LoginRequired from '../components/general/LoginRequired'
import MobileChatNavbarCustomer from '../components/screens/MobileChatNavbar.customer'
import { useUser } from '../hooks/user'

export default () => {
  const { userAttributes } = useUser()

  if (!userAttributes)
    return (
      <LoginRequired>
        <Paragraph style={{ marginVertical: 20, textAlign: 'center' }}>
          Bitte melden Sie sich an um mit Ihren Optiker zu chatten.
        </Paragraph>
      </LoginRequired>
    )

  if (!userAttributes['custom:localOpticianId']) {
    return <LocalOpticianRequired />
  }

  return (
    <>
      <MobileChatNavbarCustomer />
      <ChatComponent />
    </>
  )
}
