import Storage from '@aws-amplify/storage'
import { API, Auth } from 'aws-amplify'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { formatAssessment } from '../../services/assessmentFormatter'
import { getAllAssessments, getAllPassports } from '../../services/dbMethods'
import Fuse from 'fuse.js'
import DeviceStore from '../../services/deviceStore'

const useProvideOptician = () => {
  const [customers, setCustomers] = useState([])
  const [customerGlassAssessment, setCustomerGlassAssessment] = useState(null)
  const [customerLenseAssessment, setCustomerLenseAssessment] = useState(null)
  const [customerRawLenseAssessment, setCustomerRawLenseAssessment] = useState(
    null
  )
  const [customerLensePassports, setCustomerLensePassports] = useState()
  const [customerGlassPassports, setCustomerGlassPassports] = useState()
  const [currentCustomer, setCurrentCustomer] = useState()
  const [customerPicture, setCustomerPicture] = useState()
  const [recentCustomers, setRecentCustomers] = useState([])

  const customerId = currentCustomer?.userAttributes.sub
  const customerAttributes = currentCustomer?.userAttributes

  useEffect(() => {
    fetchRecent()
  }, [])

  const selectCustomerFromID = (customerList, sub) => {
    let result = []

    let customerSearch = new Fuse(customerList, {
      keys: ['userAttributes.sub']
    })

    console.log(
      'customerList: ' + JSON.stringify({ customerList, sub }, null, 4)
    )
    if (!customerSearch || !customerList || customerList.length === 0) {
      return null
    }
    result = customerSearch?.search(sub)
    console.log('result: ' + JSON.stringify(result, null, 4))
    if (result.length > 0) return setCurrentCustomer(result[0].item) // select best match
    return null
  }

  const getCustomerFromID = (customerList, sub) => {
    if (!customerList || !sub) return null
    let customerSearch = new Fuse(customerList, {
      keys: ['userAttributes.sub']
    })
    if (!customerSearch || !customerList || customerList.length === 0) {
      return null
    }
    return customerSearch?.search(sub)
  }

  const selectCurrentCustomer = customer => {
    const id = customer?.userAttributes?.sub
    if (id) {
      handleRecent(customer)
      setCurrentCustomer(customer)
      getFormattedGlassAssessmentFromUserId(id)
      getFormattedLenseAssessmentFromUserId(id)
      getRawLenseAssessmentFromUserId(id)
      getLensePassportsFromUserId(id)
      getGlassPassportsFromUserId(id)
      fetchCustomerPicture(
        customer.userAttributes.picture,
        customer.userAttributes['custom:identityId']
      )
    }
  }

  const fetchRecent = async () => {
    let x = await DeviceStore.getItem('recentCustomers')
    let recent = x ? JSON.parse(x) : []
    let newRecent = []
    let i
    for (i = 0; i < 3 && i < recent.length; i++) {
      if (
        recent[i]?.timestamp &&
        Date.now() - recent[i].timestamp < 16 * 60 * 60 * 1000 // 16 hours in millisecnds
      ) {
        newRecent.push(recent[i])
      }
    }
    setRecentCustomers(x ? newRecent : [])
  }

  const handleRecent = async newUser => {
    let recent = await DeviceStore.getItem('recentCustomers')
    newUser.timestamp = Date.now()
    if (recent) recent = JSON.parse(recent)
    if (!recent || !Array.isArray(recent)) recent = []

    let newRecent = [newUser]

    let i
    for (i = 0; i < 3 && i < recent.length; i++) {
      if (
        recent[i]?.userAttributes?.sub !== newUser?.userAttributes?.sub &&
        recent[i].timestamp &&
        Date.now() - recent[i].timestamp < 16 * 60 * 60 * 1000 // 16 hours in millisecnds
      ) {
        newRecent.push(recent[i])
      }
    }

    setRecentCustomers(newRecent)
    await DeviceStore.setItem(
      'recentCustomers',
      JSON.stringify(newRecent, null, 4)
    )
  }

  const fetchCustomers = async id => {
    let myInit = {
      queryStringParameters: {
        groupname: 'shop'
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      }
    }
    return API.get('OpticianQueries', '/listUsers', myInit) // to be changed to listCustomers
      .then(res => {
        let result = processCustomerQueryResponse(res.Users)
        if (id) {
          // select newly created user if id is supplied
          selectCustomerFromID(result, id)
        }
        if (result?.length > 0) setCustomers(result)
      })
      .catch(console.error)
  }

  const registerCustomer = async (
    name,
    phone_number,
    email,
    gender,
    opticianId
  ) => {
    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`
      },
      body: { name, phone_number, email, gender, opticianId }
    }
    console.log('calling API: ' + JSON.stringify(myInit, null, 4))
    return API.post('OpticianQueries', '/createUser', myInit).catch(err =>
      console.log(JSON.stringify({ err }, null, 4))
    )
  }

  const processCustomerQueryResponse = inp => {
    let customerList = []
    inp.map(x => {
      let username = x.Username
      let userCreateDate = x.UserCreateDate
      let userAttributes = {}
      x.Attributes.map(y => {
        userAttributes[y.Name] = y.Value
      })
      const userObj = { username, userCreateDate, userAttributes }
      customerList.push(userObj)
    })
    return customerList
  }

  const getCustomerAssessments = () => {
    if (customerId) {
      getFormattedGlassAssessmentFromUserId(customerId)
      getFormattedLenseAssessmentFromUserId(customerId)
      getRawLenseAssessmentFromUserId(customerId)
    }
  }

  const getFormattedGlassAssessmentFromUserId = userId => {
    getAllAssessments(userId).then(data => {
      const a = findLastAssessment(data, 'GLASSES')
      setCustomerGlassAssessment(
        a ? formatAssessment(a, a.version, 'GLASSES') : 'unfinished'
      )
    })
  }

  const getFormattedLenseAssessmentFromUserId = userId => {
    getAllAssessments(userId).then(data => {
      const a = findLastAssessment(data, 'LENSES')
      setCustomerLenseAssessment(
        a ? formatAssessment(a, a.version, 'LENSES') : 'unfinished'
      )
    })
  }

  const getRawLenseAssessmentFromUserId = userId => {
    getAllAssessments(userId).then(data => {
      const a = findLastAssessment(data, 'LENSES')
      if (a?.assessmentData) a.assessmentData = JSON.parse(a.assessmentData)
      setCustomerRawLenseAssessment(a || 'unfinished')
    })
  }

  const getCustomerPassports = () => {
    if (customerId) {
      getLensePassportsFromUserId(customerId)
      getGlassPassportsFromUserId(customerId)
    }
  }

  const getLensePassportsFromUserId = userId => {
    getAllPassports(userId, 'LENSES').then(data => {
      setCustomerLensePassports(data)
    })
  }

  const getGlassPassportsFromUserId = userId => {
    getAllPassports(userId, 'GLASSES').then(data => {
      setCustomerGlassPassports(data)
    })
  }

  const fetchCustomerPicture = (picture, identityId) => {
    if (picture && identityId) {
      return Storage.get(picture, { level: 'protected', identityId }).then(
        setCustomerPicture
      )
    } else {
      setCustomerPicture(undefined)
      return Promise.resolve()
    }
  }

  const getCustomerPicture = (picture, identityId) => {
    if (picture && identityId) {
      return Storage.get(picture, { level: 'protected', identityId })
    } else {
      return Promise.reject()
    }
  }

  return {
    customers,
    customerGlassAssessment,
    customerLenseAssessment,
    customerLensePassports,
    customerRawLenseAssessment,
    customerGlassPassports,
    currentCustomer,
    customerId,
    customerAttributes,
    customerPicture,
    recentCustomers,
    selectCurrentCustomer,
    fetchCustomers,
    fetchCustomerPicture,
    getCustomerAssessments,
    getCustomerPassports,
    registerCustomer,
    selectCustomerFromID,
    getCustomerFromID,
    getCustomerPicture
  }
}

// #region helper functions

function findLastAssessment (assessments, type) {
  let res = null
  assessments.map(a => {
    if (a.type === type) res = a
  })
  return res
}
// #endregion helper functions

const OpticianContext = createContext()

export const OpticianProvider = ({ children }) => {
  const value = useProvideOptician()

  return (
    <OpticianContext.Provider value={value}>
      {children}
    </OpticianContext.Provider>
  )
}

export const useOptician = () => useContext(OpticianContext)
