import React, { useEffect, useState } from 'react'
import { Image, Text, TouchableOpacity, View } from 'react-native'
import { Divider, IconButton, Menu } from 'react-native-paper'
import theme from '../../../styles/theme'
import MaleAvatar from '../../../../assets/undraw/undraw_male_avatar_323b.png'
import { useAppointment } from '../../../hooks/appointments'
import { useOptician } from '../../../hooks/opticians'

export default props => {
  const {
    userId,
    name,
    customer,
    comment,
    createdTimestamp
    //  notify,
    //  createdAt
  } = props

  const [customerPicture, setCustomerPicture] = useState()
  const { getCustomerPicture } = useOptician()
  const { deleteAppointment } = useAppointment()

  useEffect(() => {
    if (
      customer?.userAttributes &&
      customer?.userAttributes['custom:identityId'] &&
      customer.userAttributes.picture
    )
      getCustomerPicture(
        customer.userAttributes.picture,
        customer?.userAttributes['custom:identityId']
      ).then(setCustomerPicture)
  }, [])

  const startTime = new Date(props.appointmentTimestamp)
    .toLocaleTimeString('de-de')
    .substring(0, 5)
  const endTime = props.appointmentEndTimestamp
    ? ' - ' +
      new Date(props.appointmentEndTimestamp)
        .toLocaleTimeString('de-de')
        .substring(0, 5)
    : ''

  const [menuVisible, setMenuVisible] = useState(false)

  return (
    <TouchableOpacity
      onPress={() => setMenuVisible(true)}
      style={{
        backgroundColor: '#fff',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        minHeight: 80,
        minWidth: 120,
        marginVertical: 6,
        marginRight: 6
      }}
    >
      <View style={{ flex: 10 }}>
        <Text style={{ fontSize: 15, color: '#444', marginBottom: 10 }}>
          {startTime + endTime}
        </Text>
        <Text style={{ fontSize: 17, color: '#000', marginBottom: 8 }}>
          {customer ? customer?.userAttributes?.name : ''}
        </Text>
        <Text style={{ fontSize: 14, color: theme.colors.gray }}>{name}</Text>
        <Text style={{ fontSize: 13, color: theme.colors.gray }}>
          {comment || ''}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          minWidth: 50,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Image
          style={{
            height: 50,
            width: 50,
            maxWidth: '100%',
            maxHeight: '100%',
            borderRadius: 25,
            alignSelf: 'flex-start',
            marginRight: 0
          }}
          source={customerPicture ? { uri: customerPicture } : MaleAvatar}
          resizeMode='contain'
        />
        <Menu
          visible={menuVisible}
          onDismiss={() => setMenuVisible(false)}
          anchor={
            <IconButton
              icon='dots-vertical'
              onPress={() => setMenuVisible(true)}
            />
          }
        >
          <Menu.Item
            onPress={() => {
              alert(JSON.stringify({ props }, null, 4))
            }}
            title='Termin Debug'
            icon='eye-outline'
          />
          <Menu.Item
            onPress={() => {}}
            title='Termin bearbeiten (todo)'
            icon='pencil'
          />
          <Divider />
          <Menu.Item
            onPress={() => {
              deleteAppointment(userId, createdTimestamp.toString())
            }}
            title='Termin löschen (todo)'
            icon='delete'
          />
        </Menu>
      </View>
    </TouchableOpacity>
  )
}
