import React, { useEffect, useState } from 'react'
import { Platform, Image, View, StatusBar, Text } from 'react-native'
import theme from '../../styles/theme'
import Logo from '../../../assets/logo_text.png'
import { useUser } from '../../hooks/user'
import { useFocusEffect } from '@react-navigation/native'
import { Subheading } from 'react-native-paper'
import SkeletonContent from 'react-native-skeleton-content'
import { useMarker } from '../../hooks/map/marker'

export default ({ children }) => {
  const { userAttributes } = useUser()
  const { markerList, fetchMarkerList, getLocalOpticianFromId } = useMarker()

  if (Platform.OS !== 'web') {
    useFocusEffect(
      React.useCallback(() => {
        Platform.OS === 'android' && StatusBar.setBackgroundColor('#ffffff00')
        StatusBar.setBarStyle('dark-content')
      })
    )

    const [localOptician, setLocalOptician] = useState()

    useEffect(() => {
      fetchMarkerList()
    }, [markerList])

    useEffect(() => {
      if (
        markerList &&
        userAttributes &&
        userAttributes['custom:localOpticianId']
      ) {
        setLocalOptician(
          getLocalOpticianFromId(userAttributes['custom:localOpticianId'])
        )
      }
    }, [userAttributes, markerList])

    return (
      // mobile top navbar
      <View>
        <View
          style={{
            width: '100%',
            height: 90,
            paddingTop: 40,
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 0
            },
            shadowOpacity: 0.3,
            shadowRadius: 4.65,
            elevation: 10,
            backgroundColor: theme.colors.background
          }}
        >
          <SkeletonContent
            isLoading={!localOptician?.name}
            containerStyle={{ flex: 1, alignSelf: 'center' }}
            layout={[
              {
                key: 'OpticianName',
                width: '80%',
                height: 20,
                marginRight: 40
              }
            ]}
          >
            <Subheading style={{ alignSelf: 'flex-start', fontSize: 18 }}>
              <Text style={{ color: theme.colors.gray }}>Chat mit </Text>
              {localOptician?.name}
            </Subheading>
          </SkeletonContent>
          <Image source={Logo} style={{ height: 40, width: 120, margin: 5 }} />
        </View>
      </View>
    )
  }

  return (
    <View
      style={{
        marginTop: 15,
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap'
      }}
    >
      {children}
    </View>
  ) // web only
}
