import React, { createContext, useContext, useEffect, useState } from 'react'
import { API, graphqlOperation } from '@aws-amplify/api'
import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'
import moment from 'moment'
import { useUser } from '../user'

const useProvideAppointment = () => {
  const [appointments, setAppointments] = useState()
  const { userAttributes } = useUser()

  useEffect(() => {
    fetchAppointments()
  }, [])

  const fetchAppointments = () => {
    return API.graphql(
      graphqlOperation(queries.listAppointments, {
        userId: userAttributes?.sub
      })
    )
      .then(({ data }) => {
        console.log('Data: ' + JSON.stringify(data, null, 4))
        setAppointments(data.listAppointments.items)
      })
      .catch(err => console.log(JSON.stringify(err, null, 4)))
  }

  const storeAppointment = values => {
    const input = {
      userId: userAttributes?.sub,
      name: values.name,
      createdTimestamp: moment().valueOf(),
      appointmentTimestamp: values.appointmentTimestamp,
      participants: values.participants,
      comment: values.comment,
      notify: values.notify ? true : false
    }

    console.log('storing Appointment: ' + JSON.stringify(input, null, 4))
    return API.graphql(
      graphqlOperation(mutations.createAppointment, {
        input: input
      })
    ).then(() => {
      fetchAppointments()
    })
  }

  const deleteAppointment = (userId, createdTimestamp) => {
    const input = { userId, createdTimestamp }

    console.log('deleting Appointment: ' + JSON.stringify(input, null, 4))
    return API.graphql(
      graphqlOperation(mutations.deleteAppointment, { input: input })
    )
      .then(() => {
        fetchAppointments()
      })
      .catch(data => console.log('Data: ' + JSON.stringify(data, null, 4)))
  }

  return {
    appointments,
    fetchAppointments,
    storeAppointment,
    deleteAppointment
  }
}

const AppointmentContext = createContext()

export const AppointmentProvider = ({ children }) => {
  const value = useProvideAppointment()

  return (
    <AppointmentContext.Provider value={value}>
      {children}
    </AppointmentContext.Provider>
  )
}

export const useAppointment = () => useContext(AppointmentContext)
