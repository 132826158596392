import React, { useState, useCallback, useEffect } from 'react'
import { View } from 'react-native'
import { GiftedChat } from 'react-native-gifted-chat'
import { useOptician } from '../../hooks/opticians'
import UserblockComponent from './Userblock.component'

export default () => {
  const [messages, setMessages] = useState([])
  const { currentCustomer } = useOptician()

  useEffect(() => {
    setMessages([
      {
        _id: 3,
        text: 'Wie geht es Ihnen?',
        createdAt: new Date(),
        user: {
          _id: 2,
          name: 'React Native',
          avatar: 'https://placeimg.com/140/140/any'
        }
      },
      {
        _id: 2,
        text: 'Willkommen bei ViSpecs',
        createdAt: new Date(),
        user: {
          _id: 2,
          name: 'React Native',
          avatar: 'https://placeimg.com/140/140/any'
        }
      },
      {
        _id: 1,
        text: 'Optiker gewechselt zu "Hartlauer"',
        createdAt: new Date(Date.UTC(2020, 5, 11, 17, 20, 0)),
        system: true
        // Any additional custom parameters are passed through
      }
    ])
  }, [])

  const onSend = useCallback((messages = []) => {
    setMessages(previousMessages =>
      GiftedChat.append(previousMessages, messages)
    )
  }, [])

  return (
    <View style={{ flex: 1 }}>
      {currentCustomer && (
        <UserblockComponent
          appearance='selected'
          userObj={currentCustomer}
          key={0}
          index={0}
          onSelect={() => {}}
          basic
        />
      )}
      <GiftedChat
        messages={messages}
        onSend={messages => onSend(messages)}
        user={{
          _id: 1
        }}
      />
    </View>
  )
}
