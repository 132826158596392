import React from 'react'
import { Paragraph } from 'react-native-paper'
import { Image, View } from 'react-native'

export default () => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        minWidth: 200,
        alignSelf: 'center',
        alignItems: 'center'
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          width: '100%',
          paddingHorizontal: 40,
          alignSelf: 'center',
          alignItems: 'center'
        }}
      >
        <Paragraph>
          Dieser Bereich wird derzeit überarbeitet und wird in einer späteren
          Version von ViSpecs wieder verfügbar sein.
        </Paragraph>
        <Image
          source={require('../../../assets/undraw/undraw_branded_rework.png')}
          style={{ width: 1418 * 0.22, height: 884 * 0.22, marginTop: 10 }}
        />
      </View>
    </View>
  )
}
