import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs'
import React, { useEffect } from 'react'
import { Image } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import ViSpecsLogo from '../../assets/vi_trans.png'
import theme from '../styles/theme'
import OpticianNavigator from './SubRouters/OpticianNavigator'
import Contact from '../screens/Contact'
import Home from '../screens/Home'
import { useUser } from '../hooks/user'
import Admin from '../screens/Admin'
import StoreView from '../screens/StoreView'
import DrawerNavigatorOptician from './SubRouters/DrawerNavigator.optician'
import ChatWithOptician from '../screens/ChatWithOptician'

const Tab = createMaterialBottomTabNavigator()

export default () => {
  const { user, userGroups, loadUserGroups } = useUser()

  useEffect(() => {
    loadUserGroups()
  }, [user])

  return (
    <Tab.Navigator
      initialRouteName='Home'
      barStyle={{
        backgroundColor: theme.colors.background
      }}
      activeColor={theme.colors.primary}
    >
      <Tab.Screen
        name='Home'
        component={Home}
        options={() => ({
          tabBarLabel: 'ViSpecs',
          tabBarIcon: ({ color }) => (
            <Image
              style={{ height: 24, width: 24, tintColor: color }}
              source={ViSpecsLogo}
            />
          )
        })}
      />
      <Tab.Screen
        name='Optician'
        component={OpticianNavigator}
        options={{
          tabBarLabel: 'digitaler Optiker',
          tabBarIcon: ({ color }) => (
            <Icon name='glasses' color={color} size={24} />
          )
        }}
      />
      <Tab.Screen
        name='Store'
        component={StoreView}
        options={{
          tabBarIcon: ({ color }) => (
            <Icon name='shopping' color={color} size={24} />
          )
        }}
      />
      {userGroups &&
      (userGroups.includes('shop') || userGroups.includes('admin')) ? (
        <Tab.Screen
          name='Optiker'
          component={DrawerNavigatorOptician}
          options={{
            tabBarIcon: ({ color }) => (
              <Icon name='camera-gopro' color={color} size={24} />
            )
          }}
        />
      ) : (
        <Tab.Screen
          name='Chat'
          component={ChatWithOptician}
          options={{
            tabBarIcon: ({ color }) => (
              <Icon name='message-outline' color={color} size={24} />
            )
          }}
        />
      )}
      {userGroups &&
        (userGroups.includes('vispecs') || userGroups.includes('admin')) && (
          <Tab.Screen
            name='Admin'
            component={Admin}
            options={{
              tabBarIcon: ({ color }) => (
                <Icon name='wrench' color={color} size={24} />
              )
            }}
          />
        )}
      <Tab.Screen
        name='Feedback'
        component={Contact}
        options={{
          tabBarIcon: ({ color }) => (
            <Icon
              name='comment-text-multiple-outline'
              color={color}
              size={24}
            />
          )
        }}
      />
    </Tab.Navigator>
  )
}
