/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAssessment = /* GraphQL */ `
  query GetAssessment($userId: ID!, $endTimestamp: AWSTimestamp!) {
    getAssessment(userId: $userId, endTimestamp: $endTimestamp) {
      beginTimestamp
      endTimestamp
      userId
      assessmentData
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const listAssessments = /* GraphQL */ `
  query ListAssessments(
    $userId: ID
    $endTimestamp: ModelIntKeyConditionInput
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAssessments(
      userId: $userId
      endTimestamp: $endTimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        beginTimestamp
        endTimestamp
        userId
        assessmentData
        type
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($userId: ID!, $appointmentTimestamp: AWSTimestamp!) {
    getAppointment(
      userId: $userId
      appointmentTimestamp: $appointmentTimestamp
    ) {
      userId
      name
      createdTimestamp
      appointmentTimestamp
      appointmentEndTimestamp
      participants
      comment
      notify
      createdAt
      updatedAt
    }
  }
`;
export const listAppointments = /* GraphQL */ `
  query ListAppointments(
    $userId: ID
    $appointmentTimestamp: ModelIntKeyConditionInput
    $filter: ModelAppointmentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAppointments(
      userId: $userId
      appointmentTimestamp: $appointmentTimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        name
        createdTimestamp
        appointmentTimestamp
        appointmentEndTimestamp
        participants
        comment
        notify
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserMetadata = /* GraphQL */ `
  query GetUserMetadata($id: ID!) {
    getUserMetadata(id: $id) {
      id
      localOpticianId
      profilePictureId
      createdAt
      updatedAt
    }
  }
`;
export const listUserMetadatas = /* GraphQL */ `
  query ListUserMetadatas(
    $filter: ModelUserMetadataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMetadatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        localOpticianId
        profilePictureId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPassportUpload = /* GraphQL */ `
  query GetPassportUpload($userId: ID!, $uploadTimestamp: AWSTimestamp!) {
    getPassportUpload(userId: $userId, uploadTimestamp: $uploadTimestamp) {
      userId
      uploadTimestamp
      fileName
      status
      processedByUserId
      processedTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listPassportUploads = /* GraphQL */ `
  query ListPassportUploads(
    $userId: ID
    $uploadTimestamp: ModelIntKeyConditionInput
    $filter: ModelPassportUploadFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPassportUploads(
      userId: $userId
      uploadTimestamp: $uploadTimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        uploadTimestamp
        fileName
        status
        processedByUserId
        processedTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPassport = /* GraphQL */ `
  query GetPassport($userId: ID!, $createdTimestamp: AWSTimestamp!) {
    getPassport(userId: $userId, createdTimestamp: $createdTimestamp) {
      userId
      createdTimestamp
      createdByUserId
      passportUpload {
        nextToken
      }
      originalTimestamp
      metaData
      rightData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      leftData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const listPassports = /* GraphQL */ `
  query ListPassports(
    $userId: ID
    $createdTimestamp: ModelIntKeyConditionInput
    $filter: ModelPassportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPassports(
      userId: $userId
      createdTimestamp: $createdTimestamp
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        createdTimestamp
        createdByUserId
        originalTimestamp
        metaData
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
