import React, { useEffect, useState } from 'react'
import { Checkbox, IconButton, Modal, TextInput } from 'react-native-paper'
import { View, Text, StyleSheet } from 'react-native'
import { Button } from 'react-native-paper'
import { useMediaQuery } from 'react-responsive'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import theme from '../../styles/theme'
import { useUser } from '../../hooks/user'
import { Calendar } from 'react-native-calendars'
import ModernTextInput from '../general/ModernTextInput'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import WebTimePicker from '../../form-wizard/inputs/WebTimePicker'
import DropDown from 'react-native-paper-dropdown'
import { useOptician } from '../../hooks/opticians'
import { useAppointment } from '../../hooks/appointments'

export default ({ visible, onDismiss }) => {
  const { customers, fetchCustomers } = useOptician()
  const { storeAppointment } = useAppointment()

  const [loading, showLoading] = useState(false)
  const [appointmentDate, setAppointmentDate] = useState(formatDate())
  const [appointmentTime, setAppointmentTime] = useState('14:00')
  const [customerListOpen, setCustomerListOpen] = useState(false)
  const [participant, setParticipant] = useState()

  const customerList = () => {
    let res = []
    customers.map(x => {
      res.push({
        label: x?.userAttributes?.name,
        value: x?.userAttributes?.sub
      })
    })
    return res
  }

  useEffect(() => {
    fetchCustomers()
  }, [])

  function formatDate () {
    let d = new Date()
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  function formatLocalDate (date) {
    let d = date ? new Date(date) : new Date()
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    let year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return weekdays[d.getDay()] + ', ' + [day, month, year].join('.')
  }

  // eslint-disable-next-line no-unused-vars
  const { userAttributes } = useUser()

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Name für Termin erforderlich'),
    comment: Yup.string().trim(),
    notify: Yup.boolean()
  })

  const handleSubmit = values => {
    let timestamp = new Date(appointmentDate + 'T' + appointmentTime + ':00')
    storeAppointment({
      appointmentTimestamp: timestamp.getTime(),
      name: values.name,
      participants: participant,
      comment: values.comment,
      notify: values.notify
    })
      .then(() => onDismiss())
      .finally(() => {
        formik.setSubmitting(false)
        showLoading(false)
      })
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      comment: '',
      notify: true
    },
    onSubmit: values => {
      showLoading(true)
      handleSubmit(values)
    },
    validationSchema: validationSchema
  })

  const styles = StyleSheet.create({
    container: {
      alignSelf: 'center',
      alignItems: 'center',
      width: 1100,
      maxWidth: '90%',
      marginVertical: 20,
      padding: useMediaQuery({ maxWidth: 1000 }) ? 10 : 70,
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-around',
      backgroundColor: '#fff',
      shadowColor: '#000',
      shadowOpacity: 0.12,
      shadowRadius: 25,
      borderRadius: 12
    },
    imageBox: {
      flex: 1,
      alignItems: 'center',
      alignSelf: 'center',
      minWidth: 250,
      minHeight: 200,
      maxWidth: '80%'
    },
    divider: {
      width: 70
    },
    signUpText: {
      color: '#aaa',
      textAlign: 'center',
      textDecorationLine: 'underline'
    },
    description: {
      color: '#222',
      textAlign: 'center',
      marginBottom: useMediaQuery({ maxWidth: 1000 }) ? 10 : 40,
      paddingHorizontal: 8,
      fontSize: 15
    },
    image: {
      width: 1166 * 0.42,
      height: 885 * 0.42,
      maxWidth: '80%',
      margin: 20,
      alignSelf: 'center'
    },
    imageSmall: {
      width: 1166 * 0.22,
      height: 885 * 0.22,
      maxWidth: '70%',
      alignSelf: 'center',
      marginHorizontal: 20
    },
    loginBox: {
      flex: 1,
      minWidth: useMediaQuery({ maxWidth: 1000 }) ? 100 : 400,
      minHeight: 600,
      marginHorizontal: 20,
      height: '100%',
      justifyContent: 'space-evenly'
    },
    headline: {
      alignSelf: 'flex-start',
      marginTop: useMediaQuery({ maxDeviceWidth: 1000 }) ? -50 : 0,
      fontSize: useMediaQuery({ maxDeviceWidth: 700 }) ? 22 : 32,
      fontWeight: 'bold',
      marginBottom: 30
    },
    finishButton: {
      alignSelf: 'flex-end',
      width: useMediaQuery({ maxDeviceWidth: 1000 }) ? '100%' : 220,
      height: 54,
      justifyContent: 'center',
      marginTop: 10
    },
    checkContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      alignSelf: 'flex-end',
      justifyContent: 'center',
      marginTop: 30
    },
    rowFlex: {
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    linkText: {
      color: theme.colors.primary
    },
    radioText: {
      marginLeft: 10,
      fontSize: 18
    },
    radioWrapper: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      minWidth: 160
    },
    radioArea: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%'
    }
  })

  const weekdays = [
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag'
  ]

  return (
    <Modal
      visible={visible}
      onDismiss={() => {
        if (!loading) onDismiss()
        formik.setErrors([])
      }}
      contentContainerStyle={styles.container}
    >
      <View style={styles.loginBox}>
        <IconButton
          icon='close'
          onPress={() => onDismiss()}
          size={30}
          color='#aaa'
          style={{
            alignSelf: 'flex-end',
            marginRight: useMediaQuery({ maxDeviceWidth: 1000 }) ? -10 : -50,
            marginTop: useMediaQuery({ maxDeviceWidth: 1000 }) ? -10 : -50
          }}
        />
        <Text style={styles.headline}>Neuer Termin</Text>
        <View
          style={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <View
            style={{
              minWidth: 200,
              height: 383,
              width: '100%',
              alignSelf: 'flex-start',
              justifyContent: 'space-between',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            <Calendar
              style={{
                flex: 1,
                width: 380,
                maxWidth: '95%',
                height: 383,
                justifyContent: 'flex-start',
                alignSelf: 'flex-start',
                borderWidth: 1,
                borderColor: '#ccc',
                borderRadius: 10,
                padding: 8
              }}
              markedDates={{
                [appointmentDate]: {
                  selected: true,
                  disableTouchEvent: true,
                  selectedColor: theme.colors.vibrant,
                  selectedTextColor: '#fff'
                }
              }}
              minDate={formatDate()}
              renderArrow={direction => {
                return (
                  <Icon
                    name={'chevron-' + direction}
                    color={theme.colors.vibrant}
                    size={30}
                  />
                )
              }}
              onPressArrowLeft={subtractMonth => subtractMonth()}
              onPressArrowRight={addMonth => addMonth()}
              onDayPress={day => {
                setAppointmentDate(day.dateString)
              }}
            />
            <View
              style={{
                flex: 1,
                minWidth: 360,
                justifyContent: 'space-between'
              }}
            >
              <View
                style={{
                  width: 430,
                  maxWidth: '95%',
                  height: 265,
                  borderWidth: 1,
                  borderColor: '#ccc',
                  borderRadius: 10,
                  padding: 8,
                  alignSelf: 'flex-end'
                }}
              >
                <WebTimePicker
                  onChange={val => setAppointmentTime(val)}
                  initial={'14:00'}
                />
              </View>
              <View style={{ alignSelf: 'center' }}>
                <Text style={{ color: '#777', fontSize: 22 }}>
                  Gebuchter Termin:
                </Text>
                <Text
                  style={{
                    fontSize: 22,
                    fontWeight: '500',
                    color: theme.colors.gray
                  }}
                >
                  {formatLocalDate(appointmentDate) + ' um ' + appointmentTime}
                </Text>
              </View>
            </View>
          </View>
          <View style={{ flexDirection: 'row', width: '100%', marginTop: 30 }}>
            <View
              style={{
                flex: 1,
                width: '100%',
                minWidth: 250,
                alignSelf: 'flex-start'
              }}
            >
              <ModernTextInput
                flex={1}
                mode='flat'
                placeholder='Terminbezeichnung'
                icon='calendar-today'
                error={formik.touched.name && formik.errors.name}
                value={formik.values.name}
                onChangeText={formik.handleChange('name')}
                onBlur={() => formik.setFieldTouched('name', true)}
                errorProps={{
                  visible: formik.touched.name && formik.errors.name
                }}
                errorText={formik.errors.name}
              />
              <ModernTextInput
                flex={1}
                mode='flat'
                placeholder='Kommentar'
                multiline={true}
                icon='message-outline'
                error={formik.touched.comment && formik.errors.comment}
                value={formik.values.comment}
                onChangeText={formik.handleChange('comment')}
                errorProps={{
                  visible: formik.touched.comment && formik.errors.comment
                }}
                errorText={formik.errors.comment}
              />
            </View>
            <View
              style={{
                width: useMediaQuery({ minDeviceWidth: 1200 }) ? 60 : 0
              }}
            />
            <View style={{ flex: 1 }}>
              <DropDown
                label='Kunde'
                mode='outlined'
                value={participant}
                setValue={setParticipant}
                list={customerList()}
                visible={customerListOpen}
                showDropDown={() => setCustomerListOpen(true)}
                onDismiss={() => setCustomerListOpen(false)}
                inputProps={{
                  right: <TextInput.Icon name={'menu-down'} />
                }}
              />
            </View>
          </View>
        </View>
        <View style={styles.checkContainer}>
          <Checkbox
            disabled={loading}
            status={formik.values.notify ? 'checked' : 'unchecked'}
            onPress={() => {
              formik.setFieldValue('notify', !formik.values.notify)
            }}
          />
          <Text style={{ fontSize: 14 }}>
            Terminbestätigung an Kunden senden
          </Text>
        </View>
        <Button
          disabled={
            !loading &&
            (!formik.dirty || !formik.isValid || formik.isSubmitting)
          }
          mode={loading ? 'outlined' : 'contained'}
          style={styles.finishButton}
          onPress={() => {
            if (!loading) formik.handleSubmit()
          }}
          loading={loading}
        >
          Termin vereinbaren
        </Button>
      </View>
    </Modal>
  )
}
