import React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import OpticianDashboard from '../../screens/Optician/OpticianDashboard'
import OpticianCustomer from '../../screens/Optician/OpticianCustomer'
import OpticianChat from '../../screens/Optician/OpticianChat'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import DefaultScreen from '../../components/screens/DefaultScreen'
import { Platform, View } from 'react-native'

const Drawer = createDrawerNavigator()

// function DrawerContent () {
//   return (
//     <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
//       <Drawer.Item
//         icon='view-dashboard-outline'
//         label='Dashboard'
//         // active={active === 'Dashboard'}
//         // onPress={() => handleNav('Dashboard')}
//       />
//       <Drawer.Item
//         icon='account-outline'
//         label='Kunden'
//         // active={active === 'Customers'}
//         // onPress={() => handleNav('Customers')}
//       />
//       <Drawer.Item
//         icon='chat-outline'
//         label='Chat'
//         // active={active === 'Chat'}
//         // onPress={() => handleNav('Chat')}
//       />
//     </View>
//   )
// }

export default () => {
  return (
    <DefaultScreen>
      <View
        style={{
          justifyContent: 'flex-start',
          width: '100%',
          minHeight: '100%',
          alignSelf: 'flex-start'
        }}
      >
        <Drawer.Navigator
          drawerType={Platform.OS === 'web' ? 'permanent' : 'slide'}
          drawerStyle={{
            backgroundColor: '#fff',
            paddingTop: 10,
            shadowColor: '#000',
            shadowOffset: {
              width: 1,
              height: 1
            },
            shadowOpacity: 0.3,
            shadowRadius: 4.65,
            elevation: 4,
            borderWidth: 0,
            borderColor: '#fff'
          }}
          initialRouteName='OpticianDashboard'
        >
          <Drawer.Screen
            name='OpticianDashboard'
            options={{
              title: 'Dashboard',
              drawerIcon: ({ color, size }) => (
                <Icon name='view-dashboard-outline' color={color} size={size} />
              )
            }}
            component={OpticianDashboard}
          />
          <Drawer.Screen
            name='OpticianCustomer'
            options={{
              title: 'Kunden',
              drawerIcon: ({ color, size }) => (
                <Icon name='account-outline' color={color} size={size} />
              )
            }}
            component={OpticianCustomer}
          />
          <Drawer.Screen
            name='OpticianChat'
            options={{
              title: 'Chat',
              drawerIcon: ({ color, size }) => (
                <Icon name='message-outline' color={color} size={size} />
              )
            }}
            component={OpticianChat}
          />
        </Drawer.Navigator>
      </View>
    </DefaultScreen>
  )
}
